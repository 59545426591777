<template>
    <div>
      <v-container fluid class="back__image">
        <v-row class="responsive__style mx-auto">
          <v-col col="12" class="position_rel">
            <div class="title__style">
              <p class="black--text text-md-h4 text-h5 font-weight-bold mb-2 px-4">
                {{ $t('actions.kengash') }}
              </p>
              <v-breadcrumbs :items="elements" dark large class="pl-4">
                <template v-slot:divider>
                  <v-icon>mdi-chevron-right</v-icon>
                </template>
                <template v-slot:item="{ item }" :disabled="item.disabled">
                  <v-breadcrumbs-item>
                    <router-link style="color: black; text-decoration: none;" :to="item.href">
                      {{ $t(item.text) }}
                    </router-link>
                  </v-breadcrumbs-item>
                </template>
              </v-breadcrumbs>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid class="secondary_bg">
        <v-container class="mx-auto px-sm-5 px-0">
          <v-row class="responsive__style">
            <v-col cols="12" class="pt-5 mb-5 pl-4">
              <p class="text-h5 font-weight-bold text-start mb-1">{{ $t('actions.kengash') }}
              </p>
              <p class="iconColor" style="width: 60px; padding: 1px;"></p>
            </v-col>

            <v-col cols="12">
                <v-row class="d-flex align-start">
                  <v-col cols="12" md="4" class="order-md-1 order-2 pr-4 pl-md-6 pl-4 mb-5" style="position: sticky; top:125px">
                  <MenuTree />
                  </v-col>
                  <v-col cols="12" md="8" class="order-md-2 order-1 mb-5 px-4">
                    <v-row>
                        <v-col cols="12">
                          <img width="100%" src="../assets/image/tuzulma.jpg" alt="">
                        </v-col>
                    </v-row>
                  </v-col>
                </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
      <UsefulLink />
    </div>
  </template>

  <script>

  import MenuTree from "@/components/menuTree.vue";
  import UsefulLink from "@/components/usefulLink.vue";

  export default {
    name: "About",
    components: {
      UsefulLink,
      MenuTree
  },
    data() {
      return {
        elements: [
          {
            text: 'actions.home',
            disabled: false,
            href: 'home',
          },
          {
            text: 'actions.kengash',
            disabled: true,
            href: '/news',
          }
        ],
      }
    },
  }
  </script>

  <style lang="scss" scoped>
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "georgia";
  }
  ::v-deep .theme--dark.v-icon{
    color: black;
  }

  .back__image {
    width: 100%;
    height: 210px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3) !important;
    /** background-image: linear-gradient(to left, rgba(245, 246, 252, 0.01), #1e202f83, #1e202fcf, var(--main-color)), url('../assets/image/gfu2.jpg'); **/
    background-position: center;
    background-size: cover;
    padding: 20px;

  }
  .position_rel {
    width: 100%;
    height: 20vh;
    position: relative;
    margin: 0 auto;
  }
  .title__style {
    position: absolute;
    left: 0%;
    top: 70%;
    transform: translate(0%, -0%);
  }

  .univer__image-style{
      width: 100%;
      max-width: 500px;
      height: 320px;
      border-radius: 10px;
  }
  .univer__image-style1{
    width: 100%;
    max-width: 500px;
    height: 250px;
    border-radius: 8px;
  }

  .body__text-style{
    font-size: 18px;
    text-align: justify;
    font-family: "Roboto", sans-serif !important;
  }
  </style>
