<template>
  <router-view />
</template>

<script>
import '@/assets/main.scss'
export default {
  name: "App",
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
</style>
  