import axios from 'axios'

const state = () => ({
  talim: []
})

const getters = {
  talim(state) {
    return state.talim
  }
}

const mutations = {
  SET_TALIM(state, payload){
    state.talim = payload
  },
}

const actions = {
  getTalim({commit}){
    axios.get('https://lib.uzgeouniver.uz:82/api/main/talim-dasturlari/')
    .then((res)=>{
      commit("SET_TALIM", res.data)
    })
    .catch((error)=>{console.log(error)})
  },
  getTalimById({commit}, id){
    axios.get(`https://lib.uzgeouniver.uz:82/api/main/talim-dasturlari/${id}/`)
        .then((res)=>{
          commit("SET_TALIM", res.data)
        })
        .catch((error)=>{console.log(error)})
  },
}

export default{
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

