import axios from 'axios'

const state = () => ({
    fotos: []
})

const getters = {
    fotos(state) {
        return state.fotos
    }
}

const mutations = {
    SET_FOTOS(state, payload){
        state.fotos = payload
    },
    DELETE_FOTOS(state, id){
        state.fotos = state.fotos.filter((x) => x.id != id);
    },
    ADD_FOTOS(state, data){
        state.fotos.push(data);
    },
    EDIT_FOTOS(state, data){
        state.fotos = state.fotos.map((vm)=>{
            if(vm.id == data.id){ return data}
            else{ return vm }
        })
    }
}

const actions = {
    getFotos({commit}){
        axios.get('https://lib.uzgeouniver.uz:82/api/main/fotogalery/')
            .then((res)=>{
                commit("SET_FOTOS", res.data)
            })
            .catch((error)=>{console.log(error)})
    },
    getFotosId({commit}, id){
        axios.get(`https://lib.uzgeouniver.uz:82/api/main/fotogalery/${id}/`)
            .then((res)=>{
                commit("SET_FOTOS", res.data)
            })
            .catch((error)=>{console.log(error)})
    },
    deleteFotos({commit}, id){
        axios.delete(`https://lib.uzgeouniver.uz:82/api/main/fotogalery/${id}/`)
            .then((res)=>{
                commit("DELETE_FOTOS", id)
            })
            .catch((error)=>{console.log(error)})
    },
    addFotos({commit}, payload){
        axios.post(`https://lib.uzgeouniver.uz:82/api/main/fotogalery/`, payload)
            .then((res)=>{
                commit("ADD_FOTOS", res.data)
            })
            .catch((error)=>{console.log(error)})
    },
    editFotos({commit}, payload){
        axios.put(`https://lib.uzgeouniver.uz:82/api/main/fotogalery/${payload.id}/`, payload.data)
            .then((res)=>{
                commit("EDIT_FOTOS", res.data)
            })
            .catch((error)=>{console.log(error)})
    }

}

export default{
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
