<template>
  <div>
    <v-container class="mx-auto">
        <v-row class="responsive__style">
          <v-col cols="12" class="pt-5">
            <p class="text-h6 font-weight-bold text-center" style="text-transform: uppercase;">
              {{ $t('actions.addInfo') }}
            </p>
            <p style="width: 60px; background-color: var(--main-color); margin: 0 auto; padding: 1px;"></p>
          </v-col>
          <v-col cols="12">
            <v-tabs v-model="model" class="pt-10 mb-5" color="--main-color" left>
              <v-tab href="#1" class="text-body-1 font-weight-bold text-center mr-5 ml-3">
                Mahalliy
              </v-tab>
              <v-tab href="#2" class="text-body-1 font-weight-bold text-center">
                Qo'shma
              </v-tab>
              <v-tabs-items v-model="model">
                <v-tab-item value="1" class="py-4">
                  <v-container fluid>
                    <v-row>
                        <v-col cols="12" sm="6" md="8" lg="3" class="px-3 py-3" data-aos="fade-up" data-aos-anchor-placement="top" data-aos-duration="2000"
                        v-for="(data,index) in talim.filter(vm => vm.code == 1)" :key="index">
                        <div class="hover__effect" @click="showStudyProgramDetail(data.id)">
                          <div class="image-container">
                            <img v-if="data?.image" :src="baseUrl+data?.image" alt="Sample Image" class="imageStyle">
                            <img v-else src="@/assets/image/noImage.png" alt="Sample Image" class="imageStyle">
                            <div class="overlay">
                              <div class="text">{{
                                getName({
                                  nameUz:data?.dastur_nomi_uz,
                                  nameRu:data?.dastur_nomi_ru,
                                  nameEn:data?.dastur_nomi_en
                                })
                              }}</div>
                            </div>
                          </div>
                        </div>
                      </v-col>
<!--                    <img v-else src="@/assets/image/economyyy.png" alt="Sample Image" class="imageStyle">-->
<!--                        <v-col cols="12" sm="6" md="8" lg="3" class="px-3 py-3" data-aos="fade-up" data-aos-anchor-placement="top" data-aos-duration="1500">-->
<!--                          <div class="hover__effect" @click="showStudyProgramDetail(2)">-->
<!--                            <div class="image-container">-->
<!--                              <img src="@/assets/image/gidrogeo.jpg" alt="Sample Image" class="imageStyle">-->
<!--                              <div class="overlay">-->
<!--                                <div class="text">GIDROGEOLOGIYA VA MUHANDISLIK GEOLOGIYASI</div>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </v-col>-->
<!--                        <v-col cols="12" sm="6" md="8" lg="3" class="px-3 py-3" data-aos="fade-up" data-aos-anchor-placement="top" data-aos-duration="1500">-->
<!--                        <div class="hover__effect" @click="showStudyProgramDetail(3)">-->
<!--                            <div class="image-container">-->
<!--                              <img src="@/assets/image/ecoo.jpg" alt="Sample Image" class="imageStyle">-->
<!--                              <div class="overlay">-->
<!--                                <div class="text">EKOLOGIYA VA ATROF-MUHITNI MUHOFAZA QILISH</div>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        </v-col>-->
<!--                        <v-col cols="12" sm="6" md="8" lg="3" class="px-3 py-3" data-aos="fade-up" data-aos-anchor-placement="top" data-aos-duration="2000">-->
<!--                        <div class="hover__effect" @click="showStudyProgramDetail(4)">-->
<!--                            <div class="image-container">-->
<!--                              <img src="@/assets/image/geolofy4.jpeg" alt="Sample Image" class="imageStyle">-->
<!--                              <div class="overlay">-->
<!--                                <div class="text">GEODEZIYA, KARTOGRAFIYA VA KADASTR</div>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        </v-col>-->
                    </v-row>
                  </v-container>
                </v-tab-item>
                <v-tab-item value="2" class="py-4">
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12" md="4" class="px-3 pb-3" data-aos="fade-up" data-aos-anchor-placement="top" data-aos-duration="2200"
                      v-for="(data1,index1) in talim.filter(vm => vm.code == 2)" :key="index1">
                        <div class="hover__effect" @click="showStudyProgramDetail(data1.id)">
                          <div class="image-container">
                            <img v-if="data1?.image" :src="baseUrl+data1?.image" alt="Sample Image" class="imageStyle">
                            <img v-else src="@/assets/image/noImage.png" alt="Sample Image" class="imageStyle">
                            <div class="overlay">
                              <div class="text">
                                {{
                                  getName({
                                    nameUz:data1?.dastur_nomi_uz,
                                    nameRu:data1?.dastur_nomi_ru,
                                    nameEn:data1?.dastur_nomi_en
                                  })
                                }}</div>
                            </div>
                          </div>
                        </div>
                      </v-col>
<!--                      <img src="@/assets/image/qidiruv.jpg" alt="Sample Image" class="imageStyle">-->
<!--                      GEOLOGIK QIDIRUV ISHLARINING METODOLOGIYASI VA TEXNOLOGIYASI-->
<!--                      <v-col cols="12" md="4" class="px-3 pb-3" data-aos="fade-up" data-aos-anchor-placement="top" data-aos-duration="1000">-->
<!--                      <div class="hover__effect" @click="showStudyProgramDetail(6)">-->
<!--                          <div class="image-container">-->
<!--                            <img src="@/assets/image/geolofy6.jpg" alt="Sample Image" class="imageStyle">-->
<!--                            <div class="overlay">-->
<!--                              <div class="text">-->
<!--                                GEOLOGIYA-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                      </div>-->
<!--                      </v-col>-->
<!--                      <v-col cols="12" md="4" class="px-3 pb-3" data-aos="fade-up" data-aos-anchor-placement="top" data-aos-duration="2600">-->
<!--                        <div class="hover__effect" @click="showStudyProgramDetail(7)">-->
<!--                          <div class="image-container">-->
<!--                            <img src="@/assets/image/geolofy2.jpg" alt="Sample Image" class="imageStyle">-->
<!--                            <div class="overlay">-->
<!--                              <div class="text">-->
<!--                                NEFT VA GAZ GEOLOGIYASI-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </v-col>-->
                    </v-row>
                  </v-container>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-col>
      </v-row>
  </v-container>
</div></template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
  name: 'HomeTalimDasturi',
  data() {return {
    model: 1,
  }},
  created() {
    this.getTalim();
  },
  computed:{
    ...mapGetters('talim',['talim'])
  },
  methods:{
    ...mapActions("talim", ["getTalim"]),
    showStudyProgramDetail(id){
      this.$router.push({ name: "StudyProgramDetail", query: {id: id} });
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
}

.hover__effect {
  height: 100%;
  padding: 10px 5px;
  margin-bottom: 15px;
  transition: all 0.3s ease;
  border-radius: 15px;
  background-color: #fff;
  cursor: pointer;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.imageStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.overlay {
  padding:0 5px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  text-transform: uppercase;
  text-align: center;
  color: white;
}
</style>
