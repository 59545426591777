import axios from 'axios'

const state = () => ({
  rttm: []
})

const getters = {
  rttm(state) {
    return state.rttm
  }
}

const mutations = {
  SET_RTTM(state, payload) {
    state.rttm = payload
  }
}

const actions = {
  getRttm({ commit }) {
    axios.get('https://lib.uzgeouniver.uz:82/universitet/rttm/projects/')
      .then((res) => {
        commit("SET_RTTM", res.data)
      })
      .catch((error) => { this._vm.$toast(error.message, {type: 'error'}); })
  },
  getRttmById({ commit },id) {
    axios.get(`https://lib.uzgeouniver.uz:82/universitet/rttm/projects/${id}/`)
        .then((res) => {
          commit("SET_RTTM", res.data)
        })
        .catch((error) => { this._vm.$toast(error.message, {type: 'error'}); })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

