import axios from 'axios'

const state = () => ({
  asosiyKorsatkich: []
})

const getters = {
  asosiyKorsatkich(state) {
    return state.asosiyKorsatkich
  }
}

const mutations = {
  SET_ASOSIYKORSATKICH(state, payload){
    state.asosiyKorsatkich = payload
  }
}

const actions = {
  getAsosiyKorsatkich({commit}){
    axios.get('https://lib.uzgeouniver.uz:82/api/main/asosiy-korsatkichlar/')
    .then((res)=>{
      commit("SET_ASOSIYKORSATKICH", res.data)
    })
    .catch((error)=>{this._vm.$toast(error.message, {type: 'error'});})
  }
}

export default{
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

