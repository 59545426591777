import Vue from 'vue'
import Vuex from 'vuex'
import news from './modules/news'
import search from './modules/search'
import menu from './modules/menu'
import newsImages from './modules/newsImage'
import rektorat from './modules/rektorat'
import kafedra from './modules/kafedra'
import kafedraEmployee from './modules/kafedraEmployee'
import faculty from './modules/faculty'
import facultyEmployee from './modules/facultyEmployee'
import center from './modules/center'
import centerEmployee from './modules/centerEmployee'
import allPage from './modules/allPage'
import carousel from './modules/carousel'
import fotos from './modules/fotos'
import pagesImage from './modules/pagesImage'
import talim from './modules/talimDasturi'
import faq from './modules/faq'
import interactive from './modules/interactive'
import usefulLink from './modules/usefulLink'
import asosiyKorsatkich from './modules/asosiyKorsatkich'
import rttm from './modules/rttm'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: { news, carousel, search, menu, newsImages, rektorat, kafedra, kafedraEmployee, faculty, facultyEmployee, center, centerEmployee, allPage, fotos, pagesImage, talim, faq, interactive, usefulLink, asosiyKorsatkich, rttm },
  strict: true,
});
