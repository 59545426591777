import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            acer:colors.black,
            iconColor: '#1e202f',
            primary: colors.lightBlue,
            secondary: colors.grey.darken1,
            accent: colors.pink.darken1,
            error: colors.red.accent3,
            background: colors.white,
            secondary_bg:'#f6f8fc4d',
            info: colors.teal.darken1,
          },
          dark: {
            iconColor: '#fff',
            acer:colors.white,
            primary: colors.blue.darken2,
            background: colors.black,
            secondary_bg:colors.black,
            info: colors.teal.lighten1,
          },
        },
      },
});
