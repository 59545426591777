import axios from "axios";

const state = () => ({
  search: [],
});

const getters = {
  search(state) {
    return state.search;
  },
};

const mutations = {
  SET_SEARCH(state, payload) {
    state.search = payload;
  }
};
const actions = {
  getSearch({ commit }, payload) {
    axios.get(`https://lib.uzgeouniver.uz:82/universitet/search/?q=${payload}`)
        .then((res) => {
          commit("SET_SEARCH", res.data.result)
        })
        .catch((error) => { console.log(error)})
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
