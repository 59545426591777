import axios from 'axios'

const state = () => ({
  rektorat: {}
})

const getters = {
rektorat(state) {
    return state.rektorat
  }
}

const mutations = {
  SET_REKTORAT(state, payload){
    state.rektorat = payload
  }
}

const actions = {
  getRektorat({commit}){
    axios.get('https://lib.uzgeouniver.uz:82/api/the_only/rahbariyat/')
    .then((res)=>{
      commit("SET_REKTORAT", res.data)
    })
    .catch((error)=>{console.log(error)})
  },
  getRektoratById({commit}, id){
    axios.get(`https://lib.uzgeouniver.uz:82/api/the_only/rahbariyat/${id}/`)
    .then((res)=>{
      commit("SET_REKTORAT", res.data)
    })
    .catch((error)=>{console.log(error)})
  },
}

export default{
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

