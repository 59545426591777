import axios from 'axios'

const state = () => ({
  carousel: []
})

const getters = {
  carousel(state) {
    return state.carousel
  }
}

const mutations = {
  SET_CAROUSEL(state, payload){
    state.carousel = payload 
  },
}

const actions = {
  getCarousel({commit}){
    axios.get('https://lib.uzgeouniver.uz:82/api/main/carusel/')
    .then((res)=>{
      commit("SET_CAROUSEL", res.data)
    })
    .catch((error)=>{console.log(error)})
  },
}

export default{
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

