import axios from 'axios'

const state = () => ({
  news: []
})

const getters = {
  news(state) {
    return state.news
  }
}

const mutations = {
  SET_NEWS(state, payload){
    state.news = payload
  },
}

const actions = {
  getNews({commit}){
    axios.get('https://lib.uzgeouniver.uz:82/api/main/news/')
    .then((res)=>{
      commit("SET_NEWS", res.data.results)
    })
    .catch((error)=>{console.log(error)})
  },
  // getNewsById({commit}, id){
  //   axios.get(`http://185.233.187.134:8030/api/main/news/${id}/`)
  //   .then((res)=>{
  //     commit("SET_NEWS", res.data.results)
  //   })
  //   .catch((error)=>{console.log(error)})
  // }
}

export default{
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

