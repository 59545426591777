import axios from 'axios'

const state = () => ({
  faculty: []
})

const getters = {
    faculty(state) {
    return state.faculty
  }
}

const mutations = {
  SET_FACULTY(state, payload){
    state.faculty = payload 
  }
}

const actions = {
  getFaculty({commit}){
    axios.get('https://lib.uzgeouniver.uz:82/api/tuzilma/faculty/')
    .then((res)=>{
      commit("SET_FACULTY", res.data)
    })
    .catch((error)=>{console.log(error)})
  },
  getFacultyById({commit}, id){
    axios.get(`https://lib.uzgeouniver.uz:82/api/tuzilma/faculty/${id}/`)
    .then((res)=>{
      commit("SET_FACULTY", res.data)
    })
    .catch((error)=>{console.log(error)})
  }
}

export default{
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

