import axios from 'axios'

const state = () => ({
  pagesImages: []
})

const getters = {
  pagesImages(state) {
    return state.pagesImages
  }
}

const mutations = {
  SET_PAGES_IMAGES(state, payload){
    state.pagesImages = payload
  },
  DELETE_IMAGES(state, id){
    state.pagesImages = state.pagesImages.filter((x) => x.id != id);
  },
  ADD_IMAGES(state, data){
    state.pagesImages.push(data);
  },
  EDIT_PAGES_IMAGES(state, data){
    state.pagesImages = state.pagesImages.map((vm)=>{
      if(vm.id == data.id){ return data}
      else{ return vm }
    })
  }
}

const actions = {
  getPagesImages({commit}){
    axios.get('https://lib.uzgeouniver.uz:82/api/main/menus_image/')
    .then((res)=>{
      commit("SET_PAGES_IMAGES", res.data)
    })
    .catch((error)=>{console.log(error)})
  },
  getPagesImagesById({commit}, id){
    axios.get(`https://lib.uzgeouniver.uz:82/api/main/menus_image/${id}/`)
    .then((res)=>{
      commit("SET_PAGES_IMAGES", res.data)
    })
    .catch((error)=>{console.log(error)})
  },
  deleteImage({commit}, id){
    axios.delete(`https://lib.uzgeouniver.uz:82/api/main/menus_image/${id}/`)
    .then((res)=>{
      commit("DELETE_IMAGES", id)
    })
    .catch((error)=>{console.log(error)})
  },
  addImages({commit}, payload){
    axios.post(`https://lib.uzgeouniver.uz:82/api/main/menus_image/`, payload)
    .then((res)=>{
      commit("ADD_IMAGES", res.data)
    })
    .catch((error)=>{console.log(error)})
  },
  editPagesImages({commit}, payload){
    axios.put(`https://lib.uzgeouniver.uz:82/api/main/menus_image/${payload.id}/`, payload.data)
      .then((res)=>{
        commit("EDIT_PAGES_IMAGES", res.data)
      })
      .catch((error)=>{console.log(error)})
  }

}

export default{
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

