import axios from 'axios'

const state = () => ({
  facultyEmployee: []
})

const getters = {
    facultyEmployee(state) {
    return state.facultyEmployee
  }
}

const mutations = {
  SET_FACULTY_EMPLOYEE(state, payload){
    state.facultyEmployee = payload 
  }
}

const actions = {
  getFacultyEmployee({commit}){
    axios.get('https://lib.uzgeouniver.uz:82/api/tuzilma/faculty_employe/')
    .then((res)=>{
      commit("SET_FACULTY_EMPLOYEE", res.data)
    })
    .catch((error)=>{console.log(error)})
  },
  getFacultyEmployeeById({commit}, id){
    axios.get(`https://lib.uzgeouniver.uz:82/api/tuzilma/faculty_employe/${id}/`)
    .then((res)=>{
      commit("SET_FACULTY_EMPLOYEE", res.data)
    })
    .catch((error)=>{console.log(error)})
  }
}

export default{
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

