import App from "./App.vue";
import Vue from "vue";
import i18n from "./i18n";
import store from "./store";
import router from "./router";
import lottie from 'lottie-web';
import vuetify from "./plugins/vuetify";
import { defineElement } from 'lord-icon-element';
defineElement(lottie.loadAnimation);
// import { defineElement } from "@lordicon/element";
import Mixins from "./plugins/mixins";

Vue.use(Mixins);
Vue.config.productionTip = false;

import "swiper/css/swiper.css";
import 'swiper/swiper.scss';
import AOS from "aos";
import "aos/dist/aos.css";
import '@/assets/main.scss'

new Vue({
  created (){
    AOS.init()
  },
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
