<template>
  <div>
    <v-container fluid class="pb-md-10 pb-0">
      <v-container class="mx-auto px-0">
        <v-row class="responsive__style">
          <v-col cols="12" class=" mb-5">
            <p class="text-h6 font-weight-bold text-center" style="text-transform: uppercase;">
              {{ $t('interactive.title') }}
            </p>
            <p class="iconColor" style="width: 60px; background-color: var(--main-color); margin: 0 auto; padding: 1px;"></p>
          </v-col>

          <v-col cols="12" sm="6" md="3" lg="2" class="hover__effect pa-4" v-for="(vm, index) in interactive" :key="index">
            <a :href="vm.link" target="_blank">
              <div data-aos="fade-up" data-aos-anchor-placement="top" data-aos-duration="1500" class="sponsor__effect">
                <div class="d-block">
                  <div class="text-center">
                    <lord-icon trigger="hover" :src="`https://cdn.lordicon.com/${vm.icon_name}.json`" style="width:70px;height:70px"></lord-icon>
                  </div>
                  <p :class="{'mt-0': index == 8, 'mt-4':!(index==8)}" class="text-body-2 font-weight-bold mb-0 black--text text-center">{{
                      getName({
                        nameUz: vm?.name_uz,
                        nameRu: vm?.name_ru,
                        nameEn: vm?.name_en
                      })
                    }}
                  </p>
                </div>
              </div>
            </a>
          </v-col>


          <!-- <v-col cols="12" sm="6" md="3" lg="2" class="hover__effect pa-4" v-for="(item, index) in service" :key="index">
            <a :href="item.link" target="_blank">
              <div data-aos="fade-up" data-aos-anchor-placement="top" :data-aos-duration="item.delay" class="sponsor__effect">
                <div class="d-block">
                  <div class="text-center">
                    <lord-icon trigger="hover" :src="`https://cdn.lordicon.com/${item.icon}.json`"
                  :colors="item.color" style="width:70px;height:70px"></lord-icon>
                  </div>
                  <p :class="{'mt-0': index == 8, 'mt-4':!(index==8)}" class="text-body-2 font-weight-bold mb-0 black--text text-center">{{ $t(item.title) }}</p>
                </div>
              </div>
            </a>
          </v-col> -->

        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Interactive',
  data() {
    return {
      service: [
        {
          link: 'https://hemis.uzgeouniver.uz/dashboard/login',
          icon: 'vusrdugn',
          title: "interactive.hemisTeacher",
          color: "primary:#121331,secondary:#b26836,tertiary:#4bb3fd,quaternary:#f9c9c0,quinary:#ebe6ef",
          delay: 1000,
        },
        {
          link: 'https://student.uzgeouniver.uz/dashboard/login',
          icon: 'jefnhaqh',
          title: "interactive.hemisStudent",
          color: "outline:#121331,primary:#1e202f,secondary:#a39cf4,tertiary:#ebe6ef",
          delay: 1200,
        },
        {
          link: 'https://kontrakt.edu.uz/',
          icon: 'pqxdilfs',
          title: "interactive.contract",
          color: "outline:#131432,primary:#606874,secondary:#110a5c,tertiary:#ffffff",
          delay: 1400,
        },
        {
          link: 'https://unilibrary.uz/',
          icon: 'pqxdilfs',
          title: "interactive.unilibrary",
          color: "primary:#000000,secondary:#000000,tertiary:#ebe6ef,quaternary:#000000",
          delay: 1600,
        },
        {
          link: 'http://lib.uzgeouniver.uz/',
          icon: 'ejnrjovh',
          title: "interactive.electronLibrary",
          color:"outline:#121331,primary:#676c7c,secondary:#ebe6ef",
          delay: 1800,
        },
        {
          link: '#',
          icon: 'hdublzcw',
          title: "interactive.onlineCamera",
          color: "primary:#121331,secondary:#f9c9c0,tertiary:#ebe6ef,quaternary:#676c7c",
          delay: 3000,
        },
        {
          link: 'https://vacancy.uzgeouniver.uz/',
          icon: 'rfnfarqt',
          title: "interactive.vacancy",
          delay: 2200,
        },
        {
          link: 'https://pf.bimm.uz/',
          icon: 'ddqkigxl',
          title: "interactive.portfolia",
          color: "outline:#121331,primary:#646e78,secondary:#66eece,tertiary:#2ca58d,quaternary:#4bb3fd,quinary:#110a5c,senary:#a39cf4,septenary:#e4e4e4,octonary:#ebe6ef",
          delay: 2400,
        },
        {
          link: 'https://edo.ijro.uz/welcome',
          icon: 'cqgtrhpg',
          title: "interactive.electronDoc",
          color: "outline:#131432,primary:#606874,secondary:#a39cf4,tertiary:#ebe6ef",
          delay: 2600,
        },
        {
          link: 'https://gfu-ttj.netlify.app/',
          icon: 'pqxdilfs',
          title: "interactive.ttj",
          delay: 2800,
        },
        {
          link: 'https://student.uzgeouniver.uz/dashboard/diploma',
          icon: 'noobabzt',
          title: "interactive.checkDiplom",
          delay: 3000,
        },
        {
          link: 'https://murojaat.uzgeouniver.uz/',
          icon: 'xcctctro',
          title: "interactive.onlineAppeal",
          color: "primary:#121331,secondary:#f9c9c0,tertiary:#ebe6ef,quaternary:#676c7c",
          delay: 2000,
        },

      ]
    }
  },
  created(){
    this.getInteractive()
  },
  computed:{
    ...mapGetters("interactive",["interactive"]),
  },
  methods:{
    ...mapActions("interactive", ["getInteractive"]),
  }
}
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
}
.hover__effect {
  position: relative;
  transition: all 0.3s ease;
}

.sponsor__effect {
  width: 100%;
  padding: 30px 0;
  display: flex;
  justify-content: center;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .2);
  transition: all 0.5s ease;
}

.hover__effect:hover .sponsor__effect {
  transform: translateY(-10px);
}

</style>
