import axios from 'axios'

const state = () => ({
  interactive: []
})

const getters = {
  interactive(state) {
    return state.interactive
  }
}

const mutations = {
  SET_INTERACTIVE(state, payload){
    state.interactive = payload
  }
}

const actions = {
  getInteractive({commit}){
    axios.get('https://lib.uzgeouniver.uz:82/api/main/interaktiv-xizmatlar/')
    .then((res)=>{
      commit("SET_INTERACTIVE", res.data)
    })
    .catch((error)=>{this._vm.$toast(error.message, {type: 'error'});})
  }
}

export default{
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

