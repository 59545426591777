import axios from 'axios'

const state = () => ({
  faq: []
})

const getters = {
  faq(state) {
    return state.faq
  }
}

const mutations = {
  SET_FAQ(state, payload) {
    state.faq = payload
  }
}

const actions = {
  getFaq({ commit }) {
    axios.get('https://lib.uzgeouniver.uz:82/api/main/savol-javoblar/')
      .then((res) => {
        commit("SET_FAQ", res.data)
      })
      .catch((error) => { this._vm.$toast(error.message, {type: 'error'}); })
  },
  getFaqById({ commit }, id) {
    axios.get(`https://lib.uzgeouniver.uz:82/api/main/savol-javoblar/${id}/`)
        .then((res) => {
          commit("SET_FAQ", res.data)
        })
        .catch((error) => { this._vm.$toast(error.message, {type: 'error'}); })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

