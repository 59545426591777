import axios from 'axios'

const state = () => ({
  newsImages: []
})

const getters = {
 newsImages(state) {
    return state.newsImages
  }
}

const mutations = {
  SET_NEWSIMAGES(state, payload){
    state.newsImages = payload 
  }
}

const actions = {
  getNewsImages({commit}){
    axios.get('https://lib.uzgeouniver.uz:82/api/main/news_image/')
    .then((res)=>{
      commit("SET_NEWSIMAGES", res.data)
    })
    .catch((error)=>{console.log(error)})
  },
  getNewsImagesById({commit}, id){
    axios.get(`https://lib.uzgeouniver.uz:82/api/main/news_image/${id}/`)
    .then((res)=>{
      commit("SET_NEWSIMAGES", res.data)
    })
    .catch((error)=>{console.log(error)})
  }
}

export default{
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

